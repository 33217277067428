import React from "react";
import Logo from '../assets/icon.png';
import axios from "axios";

function Main() {

    const [inputForm,setInputForm] = React.useState({
        CompanyName:'',
        ProjectName:'',
        ProjectLoc:'',
        ProjectReq:'',
        ProjectDis:''
    });
    
    const apiUrl = 'https://api.motleyscape.com/projects/';

    const [generatedURL,setURL] = React.useState('');
    const [errorMsg,setErrorMsg] = React.useState('');

    const handleChange = (event) =>{
        setInputForm({ ...inputForm, [event.target.name]:event.target.value });
    };

    const handleSubmit = () =>{
        if(inputForm.CompanyName === null || inputForm.CompanyName === undefined || inputForm.CompanyName === ''){
            setErrorMsg('Please provide company name.');
            setTimeout(()=>{
				setErrorMsg('');
			},3000);
        }else
        if(inputForm.ProjectName === null || inputForm.ProjectName === undefined || inputForm.ProjectName === ''){
            setErrorMsg('Please provide project name.');
            setTimeout(()=>{
				setErrorMsg('');
			},3000);
        }else
        if(inputForm.ProjectLoc === null || inputForm.ProjectLoc === undefined || inputForm.ProjectLoc === ''){
            setErrorMsg('Please provide project location.');
            setTimeout(()=>{
				setErrorMsg('');
			},3000);
        }else
        if(inputForm.ProjectReq === null || inputForm.ProjectReq === undefined || inputForm.ProjectReq === ''){
            setErrorMsg('Please provide project requirements.');
            setTimeout(()=>{
				setErrorMsg('');
			},3000);
        }else
        if(inputForm.ProjectDis === null || inputForm.ProjectDis === undefined || inputForm.ProjectDis === ''){
            setErrorMsg('Please provide project discussion.');
            setTimeout(()=>{
				setErrorMsg('');
			},3000);
        }else
        if(
            (inputForm.CompanyName !== null && inputForm.CompanyName !== '' && inputForm.CompanyName !== undefined) &&
            (inputForm.ProjectName !== null && inputForm.ProjectName !== '' && inputForm.ProjectName !== undefined) &&
            (inputForm.ProjectLoc !== null && inputForm.ProjectLoc !== '' && inputForm.ProjectLoc !== undefined) &&
            (inputForm.ProjectReq !== null && inputForm.ProjectReq !== '' && inputForm.ProjectReq !== undefined) &&
            (inputForm.ProjectDis !== null && inputForm.ProjectDis !== '' && inputForm.ProjectDis !== undefined) 
        ){
            try {
                var config = {
                    Headers:{
                        'Content-Type' : 'application/json',
                        'Access-Control-Allow-Origin' : '*'
                    }
                }
                axios.post(apiUrl,JSON.stringify(inputForm),config)
                .then(
                    res=>
                    {
                        setURL(res.data);
                    }
                );
                
            } catch (error) {
                setErrorMsg('Server Error !!!');
                setTimeout(()=>{
				    setErrorMsg('');
			    },3000);
            }
        }
    };

	return (
		<div className="container">
            <div style={{marginTop:10,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div>
                    <img src={Logo} style={{width:50,height:50}} alt='Logo'/>
                </div>
                <div style={{marginLeft:15}}>
                    <h2>Requirement Form</h2>
                </div>
            </div>
            <div style={{marginTop:20}}>
                {
                    errorMsg !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMsg}
                    </div>
                }
            </div>
            <div style={{marginTop:10}}>
                <input name="CompanyName" value={inputForm.CompanyName} onChange={handleChange} style={{marginTop:10}} className="form-control" type='text' placeholder="Company Name"/>
                <input name="ProjectName" value={inputForm.ProjectName} onChange={handleChange} style={{marginTop:10}} className="form-control" type='text' placeholder="Project Name"/>
                <input name="ProjectLoc" value={inputForm.ProjectLoc} onChange={handleChange} style={{marginTop:10}} className="form-control" type='text' placeholder="Project Location"/>
                <select name="ProjectReq" value={inputForm.ProjectReq} onChange={handleChange} style={{marginTop:10}} className="form-select" aria-label="Default select example">
                    <option selected>Select Project Requirement</option>
                    <option value="MotleyAR">MotleyAR</option>
                    <option value="MotleyVR">MotleyVR</option>
                    <option value="MotleyPV">MotleyPV</option>
                    <option value="Motley3D">Motley3D</option>
                    <option value="MotleyVerse">MotleyVerse</option>
                </select>
                <textarea name="ProjectDis" value={inputForm.ProjectDis} onChange={handleChange} style={{marginTop:10}} className="form-control" type='text' placeholder="Project discussion" rows={10}/>
            </div>
            <div style={{marginTop:10}}>
                <button className="btn btn-primary" onClick={handleSubmit}>Generate Upload Link</button>
            </div>
            <div style={{marginTop:10}}>
            {
                generatedURL !== "" &&
                <div className='row'>
                    <div className='col-11'>
                        <div className="alert alert-success" role="alert">
                            {generatedURL}
                        </div>
                    </div>
                    <div className='col-1'>
                        <button style={{marginTop:10}} type='button' className="btn btn-outline-dark" onClick={navigator.clipboard.writeText(generatedURL)}>Copy</button>
                    </div>
                </div>
            }
            </div>
        </div>
	);
}

export default Main;