import React from "react";
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import Main from "./screens/Main.jsx";
import Upload from "./screens/Upload.jsx";
import './styles.css';

function App() {
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Main/>}/>
					<Route path="/upload/:id" element={<Upload/>}/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
