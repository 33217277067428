import React from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

function Upload() {

	const [notes,setNotes] = React.useState('');
	const [files, setfiles] = React.useState([]);
	
	const [errorMsg,setErrorMsg] = React.useState('');

	const [loading,setLoading] = React.useState(false);

	const [projDet,setProjDet] = React.useState({
		CompanyName:'',
        ProjectName:'',
        ProjectLoc:'',
        ProjectReq:'',
        ProjectDis:'',
		status:''
	});

	const [uploading,setUploading] = React.useState(false);
	
	const { id } = useParams();

	const apiUrl = 'https://api.motleyscape.com/';
	const config = {
		Headers:{
			'Content-Type' : 'application/json',
			'Access-Control-Allow-Origin' : '*'
		}
	};
	const config2 = {
		Headers:{
			'Content-Type' : 'multipart/form-data',
			'Access-Control-Allow-Origin' : '*'
		}
	};
	React.useEffect(()=>{
		setLoading(true);
		axios.get(apiUrl+'projects?id='+id,config).then(resp =>{
			setProjDet({
				CompanyName:resp.data.Items[0].CompanyName,
				ProjectName:resp.data.Items[0].ProjectName,
				ProjectLoc:resp.data.Items[0].ProjectLoc,
				ProjectReq:resp.data.Items[0].ProjectReq,
				ProjectDis:resp.data.Items[0].ProjectDis,
				status:resp.data.Items[0].status
			});
			setLoading(false);
		}).catch((error)=>{
			setLoading(false);
			setProjDet({...projDet,status:'error'});
		});
	},[]);

	var filesUploaded = 0;
	function handleSubmit(){
		setLoading(true);
		setUploading(true);
		if(files.length > 0){

			for(var i = 0 ; i < files.length ; i++){
				const formData = new FormData();
				formData.append('files',files[i]);
	
				if(files[i] !== null){
					
					axios.put(apiUrl+"projects?CName="+projDet.CompanyName+"&PName="+projDet.ProjectName,formData,config2)
						.then(resp =>{
	
							filesUploaded++;
							if( filesUploaded === files.length ){
								changeStatus();
							}
							
						}).catch((error)=>{
							setLoading(false);
							setUploading(false);
							setErrorMsg('error in uploading try after some time!!!');
							setTimeout(()=>{
								setErrorMsg('');
							},3000);
					});
				}
			}
		}else{
			changeStatus();
		}

		setUploading(false);
	}

	const changeStatus = () =>{
		//Upload complete without errors
		//update data in dynamodb
		
		const data = JSON.stringify({
			uid : id,
			notes : notes
		});
		axios.put(apiUrl,data,config)
			.then(response =>{
				
				setProjDet({...projDet,status:response.data});
				setLoading(false);
			}).catch((error)=>{
				setLoading(false);
				setErrorMsg('Upload complete unable to send message');
				setTimeout(()=>{
					setErrorMsg('');
				},3000);
		});
	};

	if(loading){
		return(
			<div className='container'>
				<div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'25%'}}>
					<div className="spinner-border" role="status">
						<span className="sr-only"></span>
					</div>
				</div>
				{uploading && <p style={{textAlign:'center',marginTop:10}}>Uploading...</p>}
			</div>
		);
	}
	else
	if(projDet.status === 'upload'){

		return (
			<div className="container">
				<h2 style={{marginTop:20}}>Project Details:</h2>
				<p>id: {id}</p>
				<div className='row'>
					<div className="col-6">
						<div style={{marginTop:20}}>
							<label for="name">Company Name</label>
							<input id="name" className="form-control" type="text" placeholder={projDet.CompanyName} readOnly></input>
						</div>
					</div>
					<div className="col-6">
						<div style={{marginTop:20}}>
							<label for="pname">Project Name</label>
							<input id="pname" className="form-control" type="text" placeholder={projDet.ProjectName} readOnly></input>
						</div>
					</div>
					<div className="col-6">
						<div style={{marginTop:20}}>
							<label for="ploc">Project Location</label>
							<input id="ploc" className="form-control" type="text" placeholder={projDet.ProjectLoc} readOnly></input>
						</div>
					</div>
					<div className="col-6">
						<div style={{marginTop:20}}>
							<label for="preq">Project Requirement</label>
							<input id="preq" className="form-control" type="text" placeholder={projDet.ProjectReq} readOnly></input>
						</div>
					</div>
				</div>
				<textarea style={{marginTop:20}} className="form-control" type='text' placeholder={projDet.ProjectDis} rows={10} readOnly></textarea>
	
				<h2 style={{marginTop:20}}>Document Details:</h2>
				<div style={{marginTop:20}}>
                {
                    errorMsg !== "" &&
                    <div className="alert alert-danger" role="alert">
                        {errorMsg}
                    </div>
                }
            	</div>
				{
					files !== null && files.length > 0 &&
					<div style={{border:'1px solid #E0E0E0',padding:'10px'}}>
						{
							files.map((file,index) =>{
								return(
									<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:5}}>
										<p>{file.name}</p>
										<button className='btn btn-danger' onClick={()=>{
											setfiles(files.filter((item,i) => i !== index));
										}}>Remove</button>
									</div>
								)
							})
						}
					</div>
				}
				<button type="button" className="btn btn-primary">
					<label for='file-input'>File Upload</label>
				</button>
				<input type='file' id='file-input' onChange={(event)=>{setfiles([...files,...event.target.files]);}} multiple hidden/>
	
				<textarea value={notes} onChange={(event)=>{setNotes(event.target.value);}} style={{marginTop:10}} className="form-control" type='text' placeholder="Notes" rows={5}/>
				<button style={{marginTop:20}} onClick={handleSubmit} className='btn btn-warning btn-lg'>Submit</button>
			</div>
		);
	}else
	if(projDet.status === 'uploaded'){
		return(
		<div className='container'>
			<p style={{textAlign:'center',marginTop:'25%',fontWeight:'bold',fontSize:30}}>Thank you for submitting documents.</p>
			<p style={{textAlign:'center',fontWeight:'bold',fontSize:30}}>Motleyscape</p>
		</div>
		);
	}else
	{	
		return(
		<div className='container'>
			<p style={{textAlign:'center',marginTop:'25%',fontWeight:'bold',fontSize:30}}>You have entered invalid link.</p>
			<p style={{textAlign:'center',fontWeight:'bold',fontSize:30}}>Motleyscape</p>
		</div>
		);
	}
}

export default Upload;